const loginEs = {
    title: 'Iniciar sesión',
    subtitle: 'Bienvenido',
    sign_in_button: 'Iniciar sesión',
    sign_up_title: 'Regístrate',
    sign_up_subtitle: 'Regístrate ahora',
    errorMessages: {
        invalidPassword: 'Contraseña incorrecta',
        invalidEmail: 'Email inválido',
        couldNotInitSession: 'No fue posible iniciar sesión, intenta luego.',
    }
}

export default loginEs
