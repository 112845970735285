export default {
  defLead(state, lead){
    state.lead = lead;
  },
  defShowType(state, showType){
    state.showType = showType
  },
  defShowConfirmation(state, showConfirmation){
    state.showConfirmation = showConfirmation
  },
  defPlans(state, plans) {
    state.plans = plans
  },
  defTypeSelected(state, typeSelected) {
    state.typeSelected = typeSelected
  },
  defQuotation(state, quotation) {
    state.quotation = quotation
  }
 
}