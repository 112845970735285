import serviceActions from './actions.js'
import serviceGetters from './getters.js'
import serviceMutations from './mutations.js'

export default {
  namespaced: true,
  state(){
    return {
      lead: 2,  
      showType: false,
      showConfirmation: false,
      plans: [],
      typeSelected: null,
      quotation: null,
    }
  },
  mutations: serviceMutations,
  actions: serviceActions,
  getters: serviceGetters,
}