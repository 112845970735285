const phrasesEs = {
    //nav bar options
    quotation: 'Contrata un experto',//crea una solicitud de cotización
    quotation_requested: 'Mis servicios',//cotizaciones solicitadas
    experts: 'Expertos',//lista de expertos
    account: 'Mi cuenta',
    profile: 'Mi perfil',
    profile_edit: 'Editar perfil',


    //modules expresssions
    quotation_title: 'Cotiza para comprar',
    quotation_subtitle: 'Recibe cotizaciones para servicios a la medida.',
    services_title: 'Compra servicios',
    services_subtitle: 'Disfruta de los servicios que tenemos para ti',
    services_sell_title: 'Ofertar un servicio',
    services_sell_subtitle: 'Comienza a monetizar tus habilidades',
    msg_intro: 'Encuentra servicios y productos para mejorar la calidad de vida de tu mascota.',
    msg_intro_b: 'Paseos, adiestramiento y placas QR con perfil digital.',
    msg_provider_intro: 'Genera ganancias en tu tiempo libre.',
    msg_provider_intro_b: 'Ofrece tus productos o servicios como paseador.'
}

export default phrasesEs
