const phrasesEn = {
//nav bar options
quotation: 'Hire an expert',//crea una solicitud de cotización
quotation_requested: 'My services',//cotizaciones solicitadas
experts: 'Experts',//lista de expertos
account: 'Mi account',
profile: 'Mi profile',
profile_edit: 'Edit profile',


//modules expresssions
quotation_title: 'Hire an expert',
quotation_subtitle: 'Connect with experts willing to help with what you need',
services_title: 'Buy a service',
services_subtitle: 'Find the offer of services we have for you',
services_sell_title: 'Sell a service',
services_sell_subtitle: 'Start to monetize your skills',
//dashboard user
msg_intro: 'Find services and product to improve your pet`s quality of life.',
msg_intro_b: 'Walks, training and QR plates with digital profile.',
//dashboard provider
msg_provider_intro: 'Generate extra incomes in your spare time.',
msg_provider_intro_b: 'Offer your products or services as a dog walker.'

}

export default phrasesEn
