export default {
  lead(state){
    return state.lead
  },
  showType(state){
    return state.showType
  },
  showConfirmation(state){
    return state.showConfirmation
  },
  plans(state){
    return state.plans
  },
  typeSelected(state){
    return state.typeSelected
  }, 
  quotation(state){
    return state.quotation
  }

}