import firebase from 'firebase';
export default {
  fetchPlans({ state, commit, dispatch }){
    return new Promise ((resolve, reject) => {
      try {
        let getPlans = firebase.functions().httpsCallable("getPlans");
        getPlans().then(response => {
          let plans = response.data.data;
          let collectionFee = plans.find(plan => plan.id == 34);
          collectionFee = +collectionFee.precio;

          let displacementFee = plans.find(plan => plan.id == 35);
          displacementFee = +displacementFee.precio;

          let exclusivityFee = plans.find(plan => plan.id == 36);
          exclusivityFee = +exclusivityFee.precio;

          let cancellationFee = plans.find(plan => plan.id == 37);
          cancellationFee = +cancellationFee.precio;
          commit('defPlans', plans)
          resolve(plans)
        })
      } catch (error) {
        reject(null)
      }
    })
  }
}