
import 'core-js/stable'
import Vue from 'vue'
//import CoreuiVuePro from '@coreui/vue-pro'
import BootstrapVue from 'bootstrap-vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
// import i18n from './i18n.js'
import i18nConfig from './locales/i18n'
import VueI18n from 'vue-i18n'
import axios from 'axios';
import firebase from 'firebase'
import babelPolyfill from 'babel-polyfill'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from "vue2-google-maps";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueGeolocation from 'vue-browser-geolocation';
import { EmbedPlugin } from 'bootstrap-vue';
import VueSocialSharing from 'vue-social-sharing';
import moment from 'moment';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-select/src/scss/vue-select.scss";
import VueResource from 'vue-resource';
import allCountries from './utils/allCountries'
import VueGtag from "vue-gtag";

const optionsTel = {
  disabled: false,
  allCountries,
  autocomplete: 'on',
  inputOptions: {placeholder: 'Inserta tu celular'}
}

moment.locale('es');

Vue.filter('thousand', (value) =>{
  value = value.toString()  
  var valueNum = Number(value)
  return valueNum.toLocaleString('de-DE');
})
Vue.filter('toLowerCase', (value) =>{
  value = value.toString()    
  return value.toLowerCase().trim()
})

Vue.filter('formatDateTime2', function(value) {
  if (value != '' && value != null) {    
    return moment.utc(value).format('hh:mm a DD/MMMM')
  }else{
    return 'Indefinido'
  }
});
Vue.filter('formatDateTime1', function(value) {
  if (value != '' && value != null) {    
    return moment.utc(value).format('ddd, DD-MMM-YYYY')
  }else{
    return 'Indefinido'
  }
});
Vue.filter('formatDateTime3', function(value) {
  if (value != '' && value != null) {    
    return moment.utc(value).format('hh:mm a')
  }else{
    return 'Indefinido'
  }
});
Vue.filter('formatDateTime4', function(value) {
  if (value != '' && value != null) {    
    return moment.utc(value).format('DD-MMM-YYYY')
  }else{
    return 'Indefinido'
  }
});
Vue.use(VueGtag, {
  config: { 
    id: "UA-214552935-2", 
    params: {
    send_page_view: false
    } 
  }
});
Vue.component("v-select", vSelect);
Vue.use(BootstrapVue);
Vue.use(CoreuiVuePro)
Vue.use(VueI18n);
Vue.use(babelPolyfill);
Vue.use(Vuelidate);
Vue.use(VueGoogleMaps, {
  load: {
    // key: process.env.VUE_APP_FIREBASE_API_KEY,
    key: 'AIzaSyDH2Q73_1TQ9RqCEyqIJXDmVsey9btIhTI',
    libraries: 'places'
    // libraries: ['...']    
  }
});
Vue.use(VueGeolocation);
// Vue.use(VueTelInput);
Vue.use(VueTelInput, optionsTel)
Vue.use(EmbedPlugin);
Vue.use(VueSocialSharing);
Vue.use(VueResource);

Vue.prototype.$axios = axios;
Vue.prototype.$log = console.log.bind(console)



// app definition
var app = '';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDH2Q73_1TQ9RqCEyqIJXDmVsey9btIhTI",
  authDomain: "test-goguau.firebaseapp.com",
  projectId: "test-goguau",
  storageBucket: "test-goguau.appspot.com",
  messagingSenderId: "386636289177",
  appId: "1:386636289177:web:e37838128cb4757b29d408"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebase;

// api calls
axios.defaults.headers.get['Accepts'] = 'application/json'

const i18n = new VueI18n({
  locale: 'es', // set locale
  messages: i18nConfig, // set locale messages
})

/* eslint-disable no-new */
firebase.auth().onAuthStateChanged((user) => {   
  if (!app) {
      app = new Vue({
        el: '#app',
        router,
        store,
        //CIcon component documentation: https://coreui.io/vue/docs/components/icon
        icons,
        i18n,
        template: '<App/>',
        components: {
          App
        }
      })
  }
})


// new Vue({
//   el: '#app',
//   router,
//   store,
//   //CIcon component documentation: https://coreui.io/vue/docs/components/icon
//   icons,
//   i18n,
//   template: '<App/>',
//   components: {
//     App
//   }
// })
