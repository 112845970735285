import loginEn from './login_en.js'
import loginEs from './login_es.js'
import phrasesEn from './phrases_en.js'
import phrasesEs from './phrases_es.js'

const  i18nConfig = {
    en: {
        login: loginEn,
        phrases: phrasesEn        
    },
    es: {
        login: loginEs,
        phrases: phrasesEs

    }
}

export default i18nConfig
