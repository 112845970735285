<template>
  <router-view></router-view>
</template>

<script defer src="https://www.gstatic.com/firebasejs/7.0.0/firebase-app.js"></script>
<script defer src="https://www.gstatic.com/firebasejs/7.0.0/firebase-auth.js"></script>
<script defer src="https://www.gstatic.com/firebasejs/7.0.0/firebase-firestore.js"></script>
<script src="https://unpkg.com/vue-tel-input"></script>

<script>
import Loading from 'vue-loading-overlay';
export default {
  name: 'App',
  data () {
    return {
      loading: true
    }
  },
  components: {Loading},
  created() {    
    const currentUser = this.$firebase.auth().currentUser    
    const userId = currentUser != null ? currentUser.uid : "";
    this.$store.commit('defUserId', userId)        
    let userProfile = JSON.parse(this.$store.getters.userProfile);     
    if (!userProfile && userId){                    
        this.$store.dispatch('fetchUser', {'userId': userId}).then((response) => {          
          let userData = response;          
          if(userData){            
            this.$store.commit('defUserDbId', userData.id);
            this.$store.commit('defUserProfile', JSON.stringify(userData));
            this.$store.commit('defUserType', userData.type);
          }
          this.loading = false          
        }).catch((e) => {               
            this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">

  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
