const loginEn = {
    title: 'Sign in',
    subtitle: 'Welcome',
    sign_in_button: 'Sign in',
    sign_up_title: 'Sign up',
    sign_up_subtitle: 'Sign up now',
    errorMessages: {
        invalidPassword: 'Invalid password',
        invalidEmail: 'Invalid email',
        couldNotInitSession: 'An error with the connection, try later!',
    }
}
export default loginEn
