import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase';
import { stat } from 'fs';
import sortJsonArray from "sort-json-array";

// modules
import serviceModule from './store_modules/service/index'
Vue.use(Vuex)

const modules = {
  service: serviceModule,
}

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,

  userToken: null,
  userId: null,
  userDbId: null,
  userEmail: null,
  userProfile: null,
  userProfilePicture: null,
  profileUpdated: null,
  roomName: null,
  userRoomToken: null,
  localTracks: null,
  breeds: null,
  petToEdit: null,
  itemFeatures: null,//to buy a service
  paymentBy: null,//to register payment of services,  
  payment: null,//manual payment
  paymentResumen: null,
  isPaymentBlocked: false,
  paymentDTO: null,
  pendingPayments: [],
  clientCredits: [],
  clientCreditsToApply: [],
  requestedService: [],
  order: [],
  showOrderModal: false,
  userType: null,
  cities: null,
  cityId: 1,
  clientSchedule: null,
  walkers: null,
  showAssignServiceModal: false,
  //providerCalendar
  showPickupServiceModal: false,
  calendarProviderServices: [],
  //admin kpis
  showAdminExecutionModal: false,
  showProviderScheduleModal: false,
  showScheduleMsg: false,
  showCityModal: false,
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  },

  defUserId(state, userId) {
    state.userId = userId;
  },
  defUserProfile(state, profileInfo) {
    state.userProfile = profileInfo;
  },
  defUserDbId(state, userDbId) {
    state.userDbId = userDbId;
  },
  defBreeds(state, breeds) {
    state.breeds = breeds
  },
  defPetToEdit(state, petToEdit) {
    state.petToEdit = petToEdit
  },
  defItemFeatures(state, itemFeatures) {
    state.itemFeatures = itemFeatures
  },
  defPaymentBy(state, paymentBy) {
    state.paymentBy = paymentBy
  },
  defPaymentDTO(state, paymentDTO) {
    state.paymentDTO = paymentDTO
  },
  defPendingPayments(state, pendingPayments) {
    state.pendingPayments = pendingPayments
  },
  defClientCredits(state, clientCredits) {
    state.clientCredits = clientCredits
  },
  defClientCreditsToApply(state, clientCreditsToApply) {
    state.clientCreditsToApply = clientCreditsToApply
  },
  defPayment(state, payment){
    state.payment = payment
  },
  defPaymentResumen(state, paymentResument){
    state.paymentResumen = paymentResument
  },
  defBlockPayment(state, isblocked) {
    state.isPaymentBlocked = isblocked
  },
  defRequestedService(state, requestedService){
    state.requestedService.push(requestedService)
  },
  defCleanRequestedService(state, requestedService){
    state.requestedService = requestedService
  },
  defUserType(state, type) {
    state.userType = type;
  },
  defCities(state, cities) {
    state.cities = cities;
  },
  defCityId(state, cityId){
    state.cityId = cityId;
  },
  defClientSchedule(state, clientSchedule) {
    state.clientSchedule = clientSchedule
  },
  defOrder(state, order){
    state.order = order;
  },
  defShowOrderModal(state, showOrderModal){
    state.showOrderModal = showOrderModal
  },
  defWalkers(state, walkers){
    state.walkers = walkers;
  },
  
  defShowAssignServiceModal(state, show) {
    state.showAssignServiceModal = show;
  },
  defShowPickupServiceModal(state, show){
    state.showPickupServiceModal = show
  },
  defCalendarProviderServices(state, services){
    state.calendarProviderServices = services
  },
  defShowAdminExecutionModal(state, show){
    state.showAdminExecutionModal = show
  },
  defShowProviderScheduleModal(state, show){
    state.showProviderScheduleModal = show
  },
  defShowScheduleMsg(state, show) {
    state.showScheduleMsg = show
  },
  defShowCityModal(state, show) {
    state.showCityModal = show;
  }
}

const actions = {
  fetchUser({ state, commit, dispatch }, userCredentials) {
    // console.log('fetchUser: ', userCredentials)
    return new Promise((resolve, reject) => {
      if (!userCredentials.userId) {
        console.log('no user id')
        commit('defUserProfile', null);
      }
      try {
        let fetchUserFunc = firebase.functions().httpsCallable('getUserSet');
        fetchUserFunc({ userId: userCredentials.userId }).then((result) => {                        
          let cities = result.data.cities
          commit('defCities', cities)
          if (result.data.data != null) {
            let data = result.data.data
            // console.log('store-data: ', data) 

            if ('pets' in data) {
              let pets = data.pets  
              pets.forEach(pet => {                
                if (pet.breed != '') {
                  let breed = {
                    value: pet.breed.id,
                    label: pet.breed.nombre
                  }
                  pet['breed'] = breed
                }
              });
            }
            resolve(result.data.data);
          } else {                    
            reject(null);
          }
        }).catch((error) => {            
          console.log(error)
          reject(null);
        });
      } catch (e) {
        console.log('profile not found');
        reject(null);
      }
    })
  },
  fetchBreeds({ state, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      try {
        let fetchBreedsFunc = firebase.functions().httpsCallable('getBreeds');
        fetchBreedsFunc().then((result) => {
          if (result.data.data.length > 0) {
            let breeds = []
            result.data.data.forEach(breed => {
              let item = {
                value: breed.id,
                label: breed.nombre,
              };
              breeds.push(item)
            });
            commit('defBreeds', breeds)
            resolve(breeds);
          }
          else {
            reject([])
          }
        })
      } catch (error) {
        reject([])
      }
    })
  }, 
  fetchCities({state, commit, dispatch}, data) {
    return new Promise((resolve, reject) => {
      let getCities = firebase.functions().httpsCallable('getCities');
      getCities().then((response) => {
        let data = response.data.data;
        let cities = []
        data.map((city) => {
          if(city.id != 3 && city.id != 2){
            let item = {
              value: city.id,
              label: city.nombre,
              phone: city.celular,
              indicative: city.indicativo,
              address: city.direccion
            }
            cities.push(item)
          }
        })
        commit('defCities', sortJsonArray(cities,'label','asc'));
        resolve(cities);
      }).catch((error) => {
        reject([]);
      })
    });
  },
  fetchTagProfile({ state, commit, dispatch }, tagId) {
    return new Promise((resolve, reject) => {
      let fetchTagFunc = firebase.functions().httpsCallable('getTag')
      fetchTagFunc(tagId).then((result) => {                 
        resolve(result.data.data)
      }).catch((error) => {
        console.log(error)
        reject(null)
      })
    })
  },
  fetchWalkersByCityId({ state, commit, dispatch}, city){
    return new Promise((resolve, reject) => {
      try {
        let fetchWalkers = firebase.functions().httpsCallable('getWalkersByCity')
        fetchWalkers({city}).then((res) => {
          let data = res.data.data
          let walkers = []
          data.map((walker) => {
            let item = {
              id: walker.id,
              ciudades: {
                id: walker.ciudades.id,
                nombre: walker.ciudades.nombre
              },
              fullname: walker.fullname,              
            }
            walkers.push(item)
          });
          commit('defWalkers', walkers)
          resolve(walkers)
        })
      } catch (error) {
        reject(null)
      }
    })
  },
  fetchProviderServicesAvailable({ state, commit, dispatch}, payload){
    return new Promise((resolve, reject) => {
      try {
        console.log('storePayload: ', payload)
        let fetchProviderServices = firebase.functions().httpsCallable('getProviderServicesAvailable')
        fetchProviderServices(payload).then((res) => {
          // console.log('res: ', res)
          let data = res.data.data
          if (data) {
            let info = {
              events: data.calendarEvents,  
              totalServices: []            
            }
            if(data.servicesAssigned.length > 0)
              info.totalServices = info.totalServices.concat(data.servicesAssigned)
            if(data.servicesNotAssigned.length > 0)
              info.totalServices = info.totalServices.concat(data.servicesNotAssigned)

            info.totalServices.sort((a,b) => a.fecha_inicio - b.fecha_inicio)
            commit('defCalendarProviderServices', info.totalServices )
            resolve(info)
          } 
        })        
      } catch (error) {
        reject({data: null, error})
      }
    })
  }
  
}

const getters = {
  userId(state) {
    return state.userId;
  },
  userProfile(state) {
    return state.userProfile;
  },
  breeds(state) {
    return state.breeds;
  },
  petToEdit(state) {
    return state.petToEdit;
  },
  itemFeatures(state) {
    return state.itemFeatures;
  },
  paymentBy(state) {
    return state.paymentBy;
  },
  paymentDTO(state) {
    return state.paymentDTO;
  },
  pendingPayments(state) {
    return state.pendingPayments;
  },
  clientCredits(state) {
    return state.clientCredits;
  },
  clientCreditsToApply(state) {
    return state.clientCreditsToApply
  },
  payment(state) {
    return state.payment;
  },
  paymentResumen(state) {
    return state.paymentResumen;
  },
  blockPayment(state) {
    return state.isPaymentBlocked;
  },
  requestedService(state) {
    return state.requestedService;
  },
  userType(state) {
    return state.userType;
  },
  cities(state) {
    return state.cities;
  },
  cityId(state) {
    return state.cityId;
  },
  clientSchedule(state) {
    return state.clientSchedule;
  },
  order(state){
    return state.order;
  },
  showOrderModal(state){
    return state.showOrderModal;
  },
  walkers(state){
    return state.walkers;
  },
  showAssignServiceModal(state) {
    return state.showAssignServiceModal;
  },
  showPickupServiceModal(state){
    return state.showPickupServiceModal;
  },
  calendarProviderServices(state){
    return state.calendarProviderServices;
  },
  showAdminExecutionModal(state){
    return state.showAdminExecutionModal;
  },
  showProviderScheduleModal(state){
    return state.showProviderScheduleModal;
  },
  showScheduleMsg(state){
    return state.showScheduleMsg
  },
  showCityModal(state){
    return state.showCityModal
  }

}

export default new Vuex.Store({
  state,
  mutations,
  modules,
  actions,
  getters,
})